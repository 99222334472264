import React, { Component, ReactElement } from "react";
import {
  StaticMap,
  _MapContext as MapContext,
  NavigationControl,
} from "react-map-gl";
import DeckGL from "@deck.gl/react";
import { CompositeLayer, MapView } from "@deck.gl/core";
import MapStylesClient from "../MapStylesClient";
import ClusteredLayer from "./map/layers/ClusteredLayer";
import { STS } from "aws-sdk";
import { connect } from "react-redux";
import { getDispersedLayers } from "./map/layers/LayerConfigurations";
import FCNode from "../models/FCNode";
import Preset from "../models/Preset";
import ViewState from "../models/ViewState";
import PopupMarker from "./map/PopupMarker";
import MidwayIdentityCredentialProvider from "../MidwayClient";
import MapStyleMenu from "./MapStyleMenu";
import {
  setClustered,
  setMapStyle,
  setPresets,
  setQuery,
  setSelectedStatus,
  setSelectedTypes,
} from "../store/actions/Actions";
import AnalyticsUtils from "../utils/AnalyticsUtils";
import MapPresets from "./MapPresets";

// Set your mapbox token here
const MAP_STYLE_LOOKUP_ROLE =
  "arn:aws:iam::435443747679:role/FCMap-invokeMapsService";
export const DEFAULT_MAP_STYLE = "amazon-light-grey-terrain";

const MAP_VIEW = new MapView({ repeat: true });
export const INITIAL_VIEW_STATE = {
  longitude: -95,
  latitude: 36,
  zoom: 3,
  maxZoom: 20,
  pitch: 0,
  bearing: 0,
};

export interface StateProps {
  nodes: Array<FCNode>;
  presets: Array<Preset>;
  queries: Array<string>;
  selectedTypes: Array<string>;
  selectedStatus: Array<string>;
  clustered: boolean;
  mapStyle: string;
}

interface OwnProps {
  credentialsProvider: MidwayIdentityCredentialProvider | undefined;
  setClustered: typeof setClustered;
  setMapStyle: typeof setMapStyle;
  setPresets: typeof setPresets;
  setQuery: typeof setQuery;
  setSelectedStatus: typeof setSelectedStatus;
  setSelectedTypes: typeof setSelectedTypes;
}

type MapProps = StateProps & OwnProps;

interface State {
  selectedNodes: Array<FCNode>;
  filteredNodes: Array<FCNode>;
  showCluster: boolean;
  mapStyle: string;
  selectedPreset: Preset;
  presets: Array<Preset>;
  styleUrl: string;
  viewState: ViewState;
}

class Map extends Component<MapProps, State> {
  constructor(props: Readonly<MapProps>) {
    super(props);

    this.state = {
      selectedNodes: null,
      filteredNodes: null,
      showCluster: this.props.clustered,
      styleUrl: "",
      viewState: this.props.presets.find((preset) => preset.favorite)
        ? this.props.presets.find((preset) => preset.favorite).viewState
        : INITIAL_VIEW_STATE,
      mapStyle: this.props.mapStyle,
      selectedPreset: null,
      presets: this.props.presets,
    };
  }

  fetchStyleUrl(credentialsProvider: MidwayIdentityCredentialProvider): void {
    // Workaround suggested by Gaia team until AWS allows cross account access
    // https://w.amazon.com/bin/view/Gaia/Visualization/Languages
    if (credentialsProvider) {
      credentialsProvider.withValidCredentials(() => {
        const stsClient = new STS({
          accessKeyId: credentialsProvider.accessKeyId,
          secretAccessKey: credentialsProvider.secretAccessKey,
          sessionToken: credentialsProvider.sessionToken,
          region: credentialsProvider.region,
        });
        stsClient.assumeRole(
          {
            RoleArn: MAP_STYLE_LOOKUP_ROLE,
            RoleSessionName: "FCMapStyleLookup",
          },
          (err, data) => {
            const mapClient = MapStylesClient.newClient({
              accessKey: data.Credentials.AccessKeyId,
              secretKey: data.Credentials.SecretAccessKey,
              sessionToken: data.Credentials.SessionToken,
              region: "us-east-1",
            });
            mapClient
              .mapIdGet({ mapId: this.state.mapStyle })
              .then((result) => {
                if (result.status === 200) {
                  const map = result.data;
                  for (let i = 0; i < map.stylesheets.length; i++) {
                    if (map.stylesheets[i].guidance === "recommended") {
                      const styleUrl = map.stylesheets[i].url;
                      this.setState({ styleUrl });
                    }
                  }
                }
              })
              .catch((error) => {
                console.error(
                  "Unable to fetch the desired map style url",
                  error
                );
              });
          }
        );
      });
    }
  }

  componentDidUpdate(prevProps: MapProps): void {
    const {
      credentialsProvider,
      queries,
      nodes,
      selectedTypes,
      selectedStatus,
    } = this.props;
    if (prevProps.credentialsProvider !== credentialsProvider) {
      this.fetchStyleUrl(credentialsProvider);
    }

    if (
      prevProps.nodes !== nodes ||
      prevProps.queries !== queries ||
      prevProps.selectedTypes !== selectedTypes ||
      prevProps.selectedStatus !== selectedStatus
    ) {
      this.setFilteredNodes(nodes, queries, selectedTypes, selectedStatus);
    }
  }

  onClick(info): void {
    const { objects, object } = info;

    if (object) {
      this.setState({ selectedNodes: objects || [object] });
    }
  }

  getLayers(nodes: Array<FCNode>, showCluster: boolean): Array<CompositeLayer> {
    const layerProps = {
      data: nodes,
      pickable: true,
      getPosition: (d: FCNode) => [d.longitude, d.latitude],
      onClick: (info) => this.onClick(info),
    };

    const layers = showCluster
      ? new ClusteredLayer({ ...layerProps, id: "icon-cluster", sizeScale: 60 })
      : getDispersedLayers(nodes);
    return [layers];
  }

  setFilteredNodes(
    nodes: Array<FCNode>,
    queries: Array<string>,
    selectedTypes: Array<string>,
    selectedStatus: Array<string>
  ): void {
    const filteredNodes = nodes.filter(
      (node: FCNode) =>
        selectedTypes.includes(node.type) &&
        selectedStatus.includes(node.status) &&
        this.doesNameMatch(node, queries)
    );

    if (filteredNodes.length == 1) {
      this.setState({
        filteredNodes: filteredNodes,
        viewState: {
          ...INITIAL_VIEW_STATE,
          latitude: filteredNodes[0].latitude,
          longitude: filteredNodes[0].longitude,
          zoom: 10,
        },
        selectedNodes: filteredNodes,
      });
    } else {
      this.setState({
        filteredNodes,
        selectedNodes: null,
      });
    }
  }

  doesNameMatch(node: FCNode, queries: Array<string>): boolean {
    if (queries.length == 0) {
      return true;
    }
    return queries.some((query: string) => {
      return node.name.toLocaleLowerCase().includes(query.toLocaleLowerCase());
    });
  }

  switchMapStyle(mapStyle: string): void {
    this.setState({ mapStyle: mapStyle });
    const { credentialsProvider, setMapStyle } = this.props;
    this.fetchStyleUrl(credentialsProvider);
    setMapStyle(mapStyle);
    AnalyticsUtils.recordMapStyleEvent(mapStyle);
  }

  onClickClusterButton(clusterOption: boolean): void {
    this.setState({ showCluster: clusterOption });
    const { setClustered } = this.props;
    setClustered(clusterOption);
    AnalyticsUtils.recordClusterControlEvent(
      clusterOption ? "Clustered" : "Dispersed"
    );
  }

  onClickPreset(preset: Preset): void {
    const {
      credentialsProvider,
      setClustered,
      setMapStyle,
      setQuery,
      setSelectedStatus,
      setSelectedTypes,
    } = this.props;
    const {
      clustered,
      mapStyle,
      query,
      selectedStatus,
      selectedTypes,
      viewState,
    } = preset;

    setClustered(clustered);
    setMapStyle(mapStyle);
    setQuery(query);
    setSelectedStatus(selectedStatus);
    setSelectedTypes(selectedTypes);
    this.setState({
      showCluster: clustered,
      mapStyle: mapStyle,
      viewState: viewState,
      selectedPreset: preset,
    });
    this.fetchStyleUrl(credentialsProvider);
  }

  render(): ReactElement {
    const {
      selectedPreset,
      styleUrl,
      selectedNodes,
      showCluster,
      filteredNodes,
      viewState,
    } = this.state;
    if (styleUrl === "" || filteredNodes === null) {
      return <div></div>;
    }

    const layers = this.getLayers(filteredNodes, showCluster);
    return (
      <div className="map-container">
        <DeckGL
          layers={layers}
          views={MAP_VIEW}
          initialViewState={viewState}
          onViewStateChange={(viewState) =>
            this.setState({
              viewState: {
                bearing: viewState.viewState.bearing,
                latitude: viewState.viewState.latitude,
                longitude: viewState.viewState.longitude,
                maxZoom: viewState.viewState.maxZoom,
                pitch: viewState.viewState.pitch,
                zoom: viewState.viewState.zoom,
              },
            })
          }
          controller={{ dragRotate: true }}
          onClick={(info) => this.onClick(info)}
          ContextProvider={MapContext.Provider}
        >
          <StaticMap
            reuseMaps
            mapStyle={styleUrl}
            preventStyleDiffing={true}
            mapboxApiAccessToken=""
          />
          <div className="cluster-controls-container">
            <a
              className={`cluster-button ${showCluster ? "active" : ""}`}
              onClick={() => this.onClickClusterButton(true)}
            >
              Clustered
            </a>
            <a
              className={`cluster-button ${!showCluster ? "active" : ""}`}
              onClick={() => this.onClickClusterButton(false)}
            >
              Dispersed
            </a>
          </div>
          <NavigationControl className="nav-controls" showCompass={false} />
          <MapStyleMenu
            switchMapStyle={(mapStyle) => this.switchMapStyle(mapStyle)}
            mapStyle={this.state.mapStyle}
          />
          {selectedNodes && (
            <PopupMarker
              fcNodes={selectedNodes}
              onClosePopupMarker={() => this.setState({ selectedNodes: null })}
            />
          )}
        </DeckGL>
        <MapPresets
          viewState={viewState}
          selectedPreset={selectedPreset}
          onCreatePreset={(newPreset) =>
            this.setState({ selectedPreset: newPreset })
          }
          onClickPreset={(preset) => this.onClickPreset(preset)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state): StateProps => ({
  clustered: state.clustered,
  mapStyle: state.mapStyle,
  presets: state.presets,
  nodes: state.nodes,
  queries: state.queries,
  selectedStatus: state.selectedStatus,
  selectedTypes: state.selectedTypes,
});

export default connect(mapStateToProps, {
  setClustered,
  setMapStyle,
  setPresets,
  setQuery,
  setSelectedStatus,
  setSelectedTypes,
})(Map);
