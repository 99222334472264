import BONESMidwayJavaScriptClient from "BONESMidwayJavaScriptClient";

export default class JsClient {
  constructor(props: { onReady?: () => void }) {
    this.props = props || {};
    this.clientLoaded = this.clientLoaded.bind(this);
    this.getNodes = this.getNodes.bind(this);
    this.getIdentity = this.getIdentity.bind(this);
    new BONESMidwayJavaScriptClient({ onReady: this.clientLoaded });
  }

  clientLoaded(client: JsClient): void {
    this.client = client;
    if (this.props.onReady) {
      this.props.onReady();
    }
  }

  getIdentity(callback: () => void, errorCallback: () => void): void {
    this.client
      .get(`identity`)
      .then(function (response) {
        callback(response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (errorCallback) {
          errorCallback(error);
        }
      });
  }

  getNodes(callback: () => void, errorCallback: () => void): void {
    this.client
      .get(`nodes`)
      .then(function (response) {
        if (response.data.payload) {
          callback(atob(response.data.payload));
        } else {
          callback(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (errorCallback) {
          errorCallback(error);
        }
      });
  }
}
