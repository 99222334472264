import React, { Component, ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";

export interface OwnProps {
  title: string | React.ReactNode;
  id?: string;
  className?: string;
  children?: React.ReactNode;
}

export default class Dropdown extends Component<OwnProps> {
  render(): ReactElement {
    const { id, className, title, children } = this.props;

    return (
      <div
        id={id ? id : ""}
        className={`dropdown ${className ? className : ""}`}
      >
        <a ref={this.dropdownRef} className="dropdown-button" href="#">
          {title}
        </a>
        <FontAwesomeIcon icon={faSortDown} className="not-active" />
        <FontAwesomeIcon icon={faSortUp} className="active" />

        {children && <div className="dropdown-content">{children}</div>}
      </div>
    );
  }
}
