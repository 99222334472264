import React, { Component, ReactElement } from "react";
import { Button, Modal } from "@amzn/awsui-components-react";
import Preset from "../models/Preset";

export interface OwnProps {
  header: string;
  prompt: string;
  onComplete: (title: string) => void;
  onDismiss: () => void;
  preset: Preset;
  visibility: boolean;
}

export default class PresetModal extends Component<OwnProps> {
  onClickConfirm(): void {
    this.props.onComplete(this.props.preset.title);
  }

  render(): ReactElement {
    const { header, prompt, visibility, onDismiss } = this.props;

    return (
      <Modal
        visible={visibility}
        header={header}
        size="max"
        onDismiss={() => onDismiss()}
        footer={
          <span className="awsui-util-f-r">
            <Button
              className="cancel-button"
              variant="link"
              onClick={() => onDismiss()}
            >
              Cancel
            </Button>
            <Button
              className="confirm-button"
              variant="primary"
              onClick={() => this.onClickConfirm()}
            >
              Confirm
            </Button>
          </span>
        }
      >
        <span>
          <h5>{prompt}</h5>
        </span>
      </Modal>
    );
  }
}
