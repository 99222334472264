import { LOAD_FILTERED_NODES } from "../actions/ActionTypes";

/* Load all the filtered nodes into the store */
export default function FilteredNodeReducer(
  state = [],
  action: Record<string, unknown>
): unknown {
  switch (action.type) {
    case LOAD_FILTERED_NODES:
      return action.payload;
    default:
      return state;
  }
}
