// Inspired from Icon layer example of deck.gl
// https://github.com/visgl/deck.gl/tree/master/examples/website/icon

import { CompositeLayer } from "@deck.gl/core";
import Supercluster from "supercluster";
import LayerCallback from "./LayerCallback";
import { getLayers } from "./LayerConfigurations";

export default class ClusteredLayer extends CompositeLayer {
  static readonly LAYER_CALLBACKS: LayerCallback = {
    getPosition: (d) => d.geometry.coordinates,
    getFillColor: (d) =>
      d.properties.cluster ? [102, 102, 102] : d.properties.typeColor,
    getText: (d) =>
      d.properties.cluster ? `${d.properties.point_count}` : d.properties.name,
    getSize: (d) => (d.properties.cluster ? 25 : 18),
    getColor: (d) => (d.properties.cluster ? [255, 255, 255] : [0, 0, 0]),
  };

  shouldUpdateState({ changeFlags }: unknown): unknown {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }: unknown): void {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 16, radius: props.sizeScale });
      index.load(
        props.data.map((d) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d,
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      });
    }
  }

  getPickingInfo({ info, mode }: unknown): unknown {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== "hover") {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers(): unknown {
    const { data } = this.state;
    return getLayers(data, ClusteredLayer.LAYER_CALLBACKS);
  }
}
