import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import { Multiselect, Select } from "@amzn/awsui-components-react";
import { setSelectedStatus } from "../store/actions/Actions";
import AnalyticsUtils from "../utils/AnalyticsUtils";

export interface StateProps {
  selectedStatus: Array<string>;
}

interface DispatchProps {
  setSelectedStatus: typeof setSelectedStatus;
}

type FilterStatusProps = StateProps & DispatchProps;

interface State {
  options: Array<Select.Option>;
  selectedOptions: Array<Select.Option>;
}

const ALL_STATUS = "ALL";

export const STATUSES = [
  {
    id: "ALL",
    label: "ALL",
  },
  {
    id: "ACTIVE",
    label: "Active",
  },
  {
    id: "IDLE",
    label: "Idle",
  },
];

const DEFAULT_SELECTED_STATUSES: Array<Select.Option> = [STATUSES[1]];

class FilterStatus extends Component<FilterStatusProps, StateProps> {
  state: State = {
    options: STATUSES,
    selectedOptions: this.props.selectedStatus
      ? this.props.selectedStatus.map((status) => {
          return { id: status.toUpperCase(), label: status };
        })
      : DEFAULT_SELECTED_STATUSES,
  };

  onFilteringChange(event: CustomEvent<Select.MultiselectChangeDetail>): void {
    const { setSelectedStatus } = this.props;
    let selectedOptions: Array<Select.Option>;

    if (event.detail.selectedOption.label === ALL_STATUS) {
      if (!event.detail.deselected) {
        selectedOptions = STATUSES;
      } else {
        selectedOptions = [];
      }
    } else {
      selectedOptions = event.detail.selectedOptions.filter(
        (option) => option.label !== ALL_STATUS
      );
    }

    this.setState({ selectedOptions });

    setSelectedStatus(selectedOptions.map((fc) => fc.label));
  }

  render(): ReactElement {
    const { options } = this.state;
    const { selectedStatus } = this.props;
    const selectedOptions = selectedStatus.map((status) => {
      return { id: status.toUpperCase(), label: status };
    });
    return (
      <Multiselect
        className="multi-selector"
        selectedOptions={selectedOptions}
        options={options}
        checkboxes={true}
        placeholder="Status"
        onChange={(event: CustomEvent<Select.MultiselectChangeDetail>) =>
          this.onFilteringChange(event)
        }
        onBlur={() => {
          AnalyticsUtils.recordFilterEvent("status", selectedOptions);
        }}
        filteringType="auto"
      />
    );
  }
}

const mapStateToProps = (state): StateProps => ({
  selectedStatus: state.selectedStatus,
});

export default connect(mapStateToProps, { setSelectedStatus })(FilterStatus);
