import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import FilterType from "./FilterType";
import Search from "./Search";
import FCNode from "../models/FCNode";
import Dropdown from "./Dropdown";
import FilterStatus from "./FilterStatus";
import Notifications from "./Notifications";
import AnalyticsUtils from "../utils/AnalyticsUtils";

interface OwnProps {
  user: string;
}

interface StateProps {
  nodes: Array<FCNode>;
}

type NavProps = OwnProps & StateProps;

class Nav extends Component<NavProps> {
  downloadNodes(): void {
    AnalyticsUtils.recordDownloadNodesDataEvent();
    const { nodes } = this.props;
    const csvData = FCNode.toCSV(nodes);
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "facilities.csv");
    tempLink.click();
  }

  onClickHelpOption(title: string): void {
    AnalyticsUtils.recordHelpEvent(title);
  }

  render(): ReactElement {
    const { user } = this.props;

    return (
      <nav className="nav-container">
        <div className="nav-main awsui">
          <div className="brand-name">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="logo" />
            FC Map
          </div>
          <div
            id="type-selector-container"
            className="nav-item multi-select-container"
          >
            <FilterType />
          </div>
          <div
            id="status-selector-container"
            className="nav-item multi-select-container"
          >
            <FilterStatus />
          </div>
          <div id="search-bar-container" className="nav-item">
            <Search />
          </div>
          <Dropdown
            title="Download"
            id="download-container"
            className="nav-item"
          >
            <a href="#" onClick={(): void => this.downloadNodes()}>
              All FCs
            </a>
          </Dropdown>
          <Dropdown title="Help" id="help-container" className="nav-item">
            <a
              href="https://w.amazon.com/bin/view/Topology/FC_Map/FAQ/"
              rel="noreferrer"
              target="_blank"
              onClick={() => this.onClickHelpOption("Question or problem?")}
            >
              Question or problem?
            </a>
            <a
              href="https://w.amazon.com/index.php/Topology/FC_Map"
              rel="noreferrer"
              target="_blank"
              onClick={() => this.onClickHelpOption("Wiki")}
            >
              Wiki
            </a>
            <a
              href="https://tiny.amazon.com/2rojcp3t"
              rel="noreferrer"
              target="_blank"
              onClick={() => this.onClickHelpOption("Feature Request")}
            >
              Feature Request
            </a>
          </Dropdown>

          <Notifications />

          <div className="phonetool-container">
            <img
              src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${user}`}
              alt="phonetool image"
            />
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state): StateProps => ({
  nodes: state.nodes,
});

export default connect(mapStateToProps)(Nav);
