import React from "react";
import document from "global/document";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import configureStore from "./store/Store";
import App from "./containers/App";
import { ReduxProvider } from "./ReduxContext";
import { DEFAULT_MAP_STYLE } from "./components/Map";
import SearchQuery from "./models/SearchQuery";

const presets = JSON.parse(localStorage.getItem("presets"));

let preset = null;
let clustered = null;
let mapStyle = null;
let queries = [];
let selectedStatus = null;
let selectedTypes = null;
if (presets && presets.length) {
  preset = presets.find((preset) => preset.favorite);
  if (preset) {
    clustered = preset.clustered;
    mapStyle = preset.mapStyle;
    queries = preset.queries;
    selectedStatus = preset.selectedStatus;
    selectedTypes = preset.selectedTypes;
  }
}

const store = configureStore({
  clustered: clustered !== null ? clustered : true,
  mapStyle: mapStyle ? mapStyle : DEFAULT_MAP_STYLE,
  presets: presets ? presets : [],
  queries: queries ? queries : [],
  selectedStatus: selectedStatus ? selectedStatus : ["Active"],
  selectedTypes: selectedTypes ? selectedTypes : [],
});

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <ReduxProvider value={store}>
        <App />
      </ReduxProvider>
    </Provider>
  </Router>,
  document.body.appendChild(document.createElement("div"))
);
