import { SET_SELECTED_TYPES } from "../actions/ActionTypes";

export default function FCTypeReducer(
  state = [],
  action: Record<string, unknown>
): unknown {
  switch (action.type) {
    case SET_SELECTED_TYPES:
      return action.payload;
    default:
      return state;
  }
}
