import React from "react";

const ReduxContext = React.createContext({});

export const ReduxProvider = ReduxContext.Provider;
export const ReduxConsumer = ReduxContext.Consumer;

// https://github.com/keplergl/kepler.gl/issues/351
export function withRedux(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <ReduxConsumer>
          {(store) => <WrappedComponent store={store} {...this.props} />}
        </ReduxConsumer>
      );
    }
  };
}
