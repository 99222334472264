import { Analytics } from "aws-amplify";
import Preset from "../models/Preset";

export const ATTRIBUTES_LIMITS = 40;

export default class AnalyticsUtils {
  static recordClusterControlEvent(option: string): void {
    Analytics.record({
      name: "clusterControl",
      attributes: { option: option },
      metrics: { count: 1 },
    });
  }

  static recordDownloadNodesDataEvent(): void {
    Analytics.record({
      name: "downloadNodesData",
      metrics: { count: 1 },
    });
  }

  static recordFilterEvent(filter: string, options: { label: string }[]): void {
    let attributes = {};
    if (options.length <= ATTRIBUTES_LIMITS) {
      options.forEach((option: { label: string }) => {
        attributes = {
          ...attributes,
          [`${option.label}`]: option.label,
        };
      });
    } else {
      attributes = { All: "All" };
    }
    Analytics.record({
      name: `${filter}Filter`,
      attributes: attributes,
      metrics: { count: 1, numSelected: options.length },
    });
  }

  static recordHelpEvent(title: string): void {
    Analytics.record({
      name: "help",
      attributes: { title: title },
      metrics: { count: 1 },
    });
  }

  static recordMapStyleEvent(style: string): void {
    Analytics.record({
      name: "mapStyle",
      attributes: { style: style },
      metrics: { count: 1 },
    });
  }

  static recordNotificationEvent(title: string): void {
    Analytics.record({
      name: "notification",
      attributes: { title: title },
      metrics: { count: 1 },
    });
  }

  static recordSearchEvent(term: string): void {
    Analytics.record({
      name: "search",
      attributes: { term: term },
      metrics: { count: 1 },
    });
  }

  static recordCreatePresetEvent(preset: Preset): void {
    Analytics.record({
      name: "createPreset",
      attributes: {
        title: preset.title,
        clustered: preset.clustered.toString(),
        mapStyle: preset.mapStyle,
        query: preset.query.queryStr,
        latitude: preset.viewState.latitude.toString(),
        longitude: preset.viewState.longitude.toString(),
      },
      metrics: {
        count: 1,
        numSelectedTypes: preset.selectedTypes.length,
        numSelectedStatuses: preset.selectedStatus.length,
      },
    });
  }

  static recordSelectPresetEvent(preset: Preset): void {
    Analytics.record({
      name: "selectPreset",
      attributes: {
        title: preset.title,
        clustered: preset.clustered.toString(),
        mapStyle: preset.mapStyle,
        query: preset.query.queryStr,
        latitude: preset.viewState.latitude.toString(),
        longitude: preset.viewState.longitude.toString(),
      },
      metrics: {
        count: 1,
        numSelectedTypes: preset.selectedTypes.length,
        numSelectedStatuses: preset.selectedStatus.length,
      },
    });
  }

  static recordFavoritePresetEvent(): void {
    Analytics.record({
      name: "favoritePreset",
      metrics: { count: 1 },
    });
  }

  static recordUnfavoritePresetEvent(): void {
    Analytics.record({
      name: "unfavoritePreset",
      metrics: { count: 1 },
    });
  }

  static recordEditPresetEvent(): void {
    Analytics.record({
      name: "editPreset",
      metrics: { count: 1 },
    });
  }

  static recordDeletePresetEvent(): void {
    Analytics.record({
      name: "deletePreset",
      metrics: { count: 1 },
    });
  }

  static recordLoadFavoritePresetEvent(): void {
    Analytics.record({
      name: "loadFavoritePreset",
      metrics: { count: 1 },
    });
  }
}
