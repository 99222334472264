import { getNodesAsync, loadFilterTypeOptions } from "./Actions";
import { Dispatch } from "redux";
import _ from "lodash";
import FCNode from "../../models/FCNode";
import JsClient from "./JsClient";

export default function getData(client: JsClient): unknown {
  return (dispatch: Dispatch) => {
    client.getNodes(
      (raw: string) => {
        const fcNodesWithDuplicates = FCNode.fromCSV(raw);

        // Noticed some duplicates.  Need to verify this on the Data Sync client.
        // https://sim.amazon.com/issues/FCMAP-35
        const fcNodes = _.uniqBy(fcNodesWithDuplicates, "name").sort(
          (node1: FCNode, node2: FCNode) => {
            return node1.name.localeCompare(node2.name);
          }
        );
        const fcTypes = [
          ...new Set(fcNodes.map((fcNode: FCNode) => fcNode.type)),
        ].sort();
        const fcTypesWithAll = ["ALL", ...fcTypes];
        const fcTypeFilterOptions = fcTypesWithAll.map((type, index) => {
          return {
            label: type,
            id: index.toString(),
          };
        });

        dispatch(loadFilterTypeOptions(fcTypeFilterOptions));
        dispatch(getNodesAsync(fcNodes));
      },
      (err: Error) => {
        console.error("Error loading Data", err);
      }
    );
  };
}
