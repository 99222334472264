import React, { Component, ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLayerGroup,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";

const MAP_STYLES = [
  "amazon-light-grey-terrain",
  "amazon-delivery",
  "amazon-basic",
];

export interface OwnProps {
  switchMapStyle: (mapStyle: string) => void;
  mapStyle: string;
}

interface State {
  isChangingMapStyle: boolean;
}

export default class MapStyleMenu extends Component<OwnProps, State> {
  private state: State = {
    isChangingMapStyle: false,
  };

  render(): ReactElement {
    const { switchMapStyle, mapStyle } = this.props;

    if (this.state.isChangingMapStyle) {
      return (
        <div className="map-style-controls-container">
          <a
            className={`map-style-button-icon ${
              mapStyle === MAP_STYLES[0] ? "active" : ""
            }`}
            title="Light Grey"
            onClick={() => switchMapStyle(MAP_STYLES[0])}
            href="#"
          >
            <div className="map-style-button-icon-image light-grey" />
          </a>
          <a
            className={`map-style-button-icon ${
              mapStyle === MAP_STYLES[1] ? "active" : ""
            }`}
            title="Delivery"
            onClick={() => switchMapStyle(MAP_STYLES[1])}
            href="#"
          >
            <div className="map-style-button-icon-image delivery" />
          </a>
          <a
            className={`map-style-button-icon ${
              mapStyle === MAP_STYLES[2] ? "active" : ""
            }`}
            title="Basic"
            onClick={() => switchMapStyle(MAP_STYLES[2])}
            href="#"
          >
            <div className="map-style-button-icon-image basic" />
          </a>
          <a
            className="map-style-button"
            onClick={() =>
              this.setState((state) => ({
                isChangingMapStyle: !state.isChangingMapStyle,
              }))
            }
            title="Map Styles"
            href="#"
          >
            <FontAwesomeIcon
              icon={faAngleDoubleUp}
              className="map-style-menu-button-icon"
            />
          </a>
        </div>
      );
    } else {
      return (
        <div className="map-style-controls-container">
          <a
            className="map-style-button"
            onClick={() =>
              this.setState((state) => ({
                isChangingMapStyle: !state.isChangingMapStyle,
              }))
            }
            title="Map Styles"
            href="#"
          >
            <FontAwesomeIcon
              icon={faLayerGroup}
              className="map-style-menu-button-icon"
            />
          </a>
        </div>
      );
    }
  }
}
