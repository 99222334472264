import React, { Component, ReactElement } from "react";
import { getCookie, setCookie } from "../models/CookieManager";
import Dropdown from "./Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCircle } from "@fortawesome/free-solid-svg-icons";
import AnalyticsUtils from "../utils/AnalyticsUtils";

// TODO: Pull these from the backend or JSON instead of hardcoding.
const NOTIFICATIONS = [
  {
    title: "Dec 2020 - Map Presets",
    link:
      "https://w.amazon.com/bin/view/SCOT/Forecasting/Topology/Products/FC_Map/feature-release/#HDec2020-MapPresets",
  },
  {
    title: "Aug 2020 - New Map Styles",
    link:
      "https://w.amazon.com/bin/view/SCOT/Forecasting/Topology/Products/FC_Map/feature-release/#HAug2020-NewMapStyles",
  },
  {
    title: "Major Release - 2.0 Information Age",
    link:
      "https://w.amazon.com/bin/view/Topology/FC_Map/feature-release/#HJul2020-FCmap2.0",
  },
  {
    title: "View Co-location sites",
    link:
      "https://w.amazon.com/bin/view/Topology/FC_Map/FAQ/#HHowtoidentify2FviewaCo-Location3F",
  },
];

const LATEST_NOTIFICATION_IDX = "notification-idx";

interface State {
  highlightNotifications: boolean;
}

export default class Notifications extends Component<
  Record<string, unknown>,
  State
> {
  private state: State = {
    highlightNotifications: Notifications.highlightNotifications(),
  };

  updateLastSeenNotification(notificationTitle: string): void {
    this.setState({ highlightNotifications: false });
    setCookie(LATEST_NOTIFICATION_IDX, NOTIFICATIONS.length.toString(), 365);
    AnalyticsUtils.recordNotificationEvent(notificationTitle);
  }

  static highlightNotifications(): boolean {
    const notificationIdxCookie = getCookie(LATEST_NOTIFICATION_IDX);
    const notificationIdx =
      notificationIdxCookie === "" ? 0 : parseInt(notificationIdxCookie);

    return NOTIFICATIONS.length > notificationIdx;
  }

  render(): ReactElement {
    const { highlightNotifications } = this.state;
    const notificationBell = (
      <span>
        <FontAwesomeIcon icon={faBell} className="notification-bell" />
        {highlightNotifications && (
          <FontAwesomeIcon icon={faCircle} className="red-circle" />
        )}
      </span>
    );

    return (
      <Dropdown
        title={notificationBell}
        className="nav-item"
        id="notification-container"
      >
        {NOTIFICATIONS.map(
          (notification): React.ReactNode => {
            return (
              <a
                href={notification.link}
                key={notification.title}
                rel="noreferrer"
                target="_blank"
                onClick={() =>
                  this.updateLastSeenNotification(notification.title)
                }
              >
                {notification.title}
              </a>
            );
          }
        )}
      </Dropdown>
    );
  }
}
