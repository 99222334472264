import { CompositeLayer } from "@deck.gl/core";
import { ScatterplotLayer, TextLayer } from "@deck.gl/layers";
import FCNode from "../../../models/FCNode";
import LayerCallback from "./LayerCallback";

export function getLayers(
  data: Array<FCNode>,
  layerCallback: LayerCallback
): Array<CompositeLayer> {
  const scatterPlotLayer = new ScatterplotLayer({
    id: "point-layer",
    data,
    pickable: true,
    opacity: 0.6,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 0.8,
    radiusMinPixels: 25,
    getPosition: layerCallback.getPosition,
    getFillColor: layerCallback.getFillColor,
  });

  const textLayer = new TextLayer({
    id: "label-layer",
    data,
    pickable: true,
    fontFamily: "Amazon Ember",
    getPosition: layerCallback.getPosition,
    getText: layerCallback.getText,
    getSize: layerCallback.getSize,
    getColor: layerCallback.getColor,
    getTextAnchor: "middle",
    getAlignmentBaseline: "center",
  });

  return [scatterPlotLayer, textLayer];
}

const DISPERSED_LAYER_CALLBACKS: LayerCallback = {
  getPosition: (d: FCNode) => [d.longitude, d.latitude],
  getFillColor: (d: FCNode) => d.typeColor,
  getText: (d: FCNode) => d.name,
  getSize: 18,
  getColor: [0, 0, 0],
};

export function getDispersedLayers(data: Array<FCNode>): Array<CompositeLayer> {
  return getLayers(data, DISPERSED_LAYER_CALLBACKS);
}
