import { applyMiddleware, combineReducers, createStore } from "redux";
import { taskMiddleware } from "react-palm/tasks";
import thunk from "redux-thunk";

import NodeReducer from "./reducers/NodeReducer";
import FilterOptionReducer from "./reducers/FilterOptionReducer";
import FilteredNodeReducer from "./reducers/FilteredNodeReducer";
import ClusteredReducer from "./reducers/ClusteredReducer";
import MapStyleReducer from "./reducers/MapStyleReducer";
import PresetsReducer from "./reducers/PresetsReducer";
import QueryReducer from "./reducers/QueryReducer";
import FCTypeReducer from "./reducers/FCTypeReducer";
import FCStatusReducer from "./reducers/FCStatusReducer";
import FCNodeReducer from "./reducers/FCNodeReducer";

// Define the Reducers that will always be present in the application
const staticReducers = {
  nodes: NodeReducer,
  filterOptions: FilterOptionReducer,
  filteredNodes: FilteredNodeReducer,
  clustered: ClusteredReducer,
  mapStyle: MapStyleReducer,
  presets: PresetsReducer,
  queries: QueryReducer,
  selectedStatus: FCStatusReducer,
  selectedTypes: FCTypeReducer,
  selectedNodes: FCNodeReducer,
};

const rootReducer = combineReducers({});
export type RootState = ReturnType<typeof rootReducer>;

// Configure the store
export default function configureStore(initialState: RootState): RootState {
  const someReducers = createReducer();
  const someOtherStuff = applyMiddleware(taskMiddleware, thunk);
  const store = createStore(someReducers, initialState, someOtherStuff);

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {};

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  // Return the modified store
  return store;
}

function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
}
