import { SET_SELECTED_STATUS } from "../actions/ActionTypes";

export default function FCStatusReducer(
  state = [],
  action: Record<string, unknown>
): unknown {
  switch (action.type) {
    case SET_SELECTED_STATUS:
      return action.payload;
    default:
      return state;
  }
}
