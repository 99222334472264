import { SET_MAP_STYLE } from "../actions/ActionTypes";

export default function MapStyleReducer(
  state = "",
  action: Record<string, unknown>
): unknown {
  switch (action.type) {
    case SET_MAP_STYLE:
      return action.payload;
    default:
      return state;
  }
}
