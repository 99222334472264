import React, { Component, ReactElement } from "react";
import { connect } from "react-redux";
import { Multiselect, Select } from "@amzn/awsui-components-react";
import { setSelectedTypes } from "../store/actions/Actions";
import AnalyticsUtils from "../utils/AnalyticsUtils";

const ALL_FILERS = "ALL";

export interface StateProps {
  filterOptions: Array<Select.Option>;
  selectedTypes: Array<string>;
}

interface DispatchProps {
  setSelectedTypes: typeof setSelectedTypes;
}

interface State {
  options: Array<Select.Option>;
  selectedOptions: Array<Select.Option>;
}

type FilterTypeProps = StateProps & DispatchProps;

class FilterType extends Component<FilterTypeProps, State> {
  state: State = {
    options: [],
    selectedOptions: [],
  };

  // Handles the logic of what happens when a filter is changed
  onFilteringChange(event: CustomEvent<Select.MultiselectChangeDetail>): void {
    const { setSelectedTypes, filterOptions } = this.props;
    let selectedOptions;

    // When ALL filter is toggled, we want to display/hide all the nodes and check/uncheck filters
    if (event.detail.selectedOption.label === ALL_FILERS) {
      if (!event.detail.deselected) {
        selectedOptions = filterOptions;
      } else {
        selectedOptions = [];
      }
    } else {
      selectedOptions = event.detail.selectedOptions.filter(
        (option) => option.label !== ALL_FILERS
      );
    }

    this.setState({ selectedOptions });

    setSelectedTypes(selectedOptions.map((fc) => fc.label));
  }

  // For the initial render: waits for the filters to get loaded into the store and sets the state accordingly
  componentDidUpdate(prevProps: FilterTypeProps): void {
    const { filterOptions, selectedTypes, setSelectedTypes } = this.props;
    if (filterOptions !== prevProps.filterOptions) {
      if (!(selectedTypes && selectedTypes.length)) {
        setSelectedTypes(filterOptions.map((fc) => fc.label));
      }
      this.setState({
        options: filterOptions,
        selectedOptions: selectedTypes
          ? selectedTypes.map((type) => {
              return {
                id: filterOptions.find((option) => option.label === type).id,
                label: type,
              };
            })
          : filterOptions,
      });
    }
  }

  render(): ReactElement {
    const { options } = this.state;
    const { selectedTypes } = this.props;
    let selectedOptions = [];
    if (options.length) {
      selectedOptions = selectedTypes.map((type) => {
        return {
          id: options.find((option) => option.label === type).id,
          label: type,
        };
      });
    }
    return (
      <Multiselect
        className="multi-selector"
        id="type-selector"
        selectedOptions={selectedOptions}
        options={options}
        checkboxes={true}
        placeholder="Type"
        onChange={(event: CustomEvent<Select.MultiselectChangeDetail>) =>
          this.onFilteringChange(event)
        }
        onBlur={() => {
          AnalyticsUtils.recordFilterEvent("type", selectedOptions);
        }}
        filteringType="auto"
      />
    );
  }
}

const mapStateToProps = (state): StateProps => ({
  filterOptions: state.filterOptions,
  selectedTypes: state.selectedTypes,
});

export default connect(mapStateToProps, { setSelectedTypes })(FilterType);
