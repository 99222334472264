import React, { Component, ReactElement } from "react";
import { Autosuggest } from "@amzn/awsui-components-react";
import { setQuery } from "../store/actions/Actions";
import { connect } from "react-redux";
import FCNode from "../models/FCNode";
import SearchQuery from "../models/SearchQuery";
import AnalyticsUtils from "../utils/AnalyticsUtils";

export interface StateProps {
  nodes: Array<FCNode>;
  selectedTypes: Array<string>;
  selectedStatus: Array<string>;
}

export interface DispatchProps {
  setQuery: typeof setQuery;
}

export type SearchProps = StateProps & DispatchProps;

interface State {
  tags: Array<string>;
}

class Search extends Component<SearchProps, State> {
  state: State = {
    tags: [],
  };

  removeTag(i: number): void {
    const { tags } = this.state;
    const { setQuery } = this.props;
    tags.splice(i, 1);
    setQuery(tags);
    this.setState({ tags });
  }

  inputKeyDown(e: KeyboardEvent): void {
    const val = e.target.value;
    const { tags } = this.state;
    const { setQuery } = this.props;
    if (e.key === "Enter" && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      const newTags = [...tags, val];
      setQuery(newTags);
      this.setState({ tags: newTags });
      e.target.value = "";
    } else if (e.key === "Backspace" && !val) {
      this.removeTag(this.state.tags.length - 1);
    }
  }

  render(): ReactElement {
    const { tags } = this.state;

    const pills = tags.map((tag: string, index: number) => {
      return (
        <div key={`pill-${tag}`} className="pill-item">
          {tag}
          <a
            href="#"
            className="pill-close"
            onClick={() => this.removeTag(index)}
          >
            x
          </a>
        </div>
      );
    });

    return (
      <div className="auto-suggest">
        {pills}
        <input
          type="text"
          className="search-input"
          onKeyDown={(e: KeyboardEvent) => this.inputKeyDown(e)}
        />
      </div>
    );
  }
}

const mapStateToProps = (state): StateProps => ({
  nodes: state.nodes,
  selectedTypes: state.selectedTypes,
  selectedStatus: state.selectedStatus,
});

export default connect(mapStateToProps, { setQuery })(Search);
