import { LOAD_FILTER_TYPE_OPTIONS } from "../actions/ActionTypes";

/* Load all the filters into the store */
export default function FilterOptionReducer(
  state = [],
  action: Record<string, unknown>
): unknown {
  switch (action.type) {
    case LOAD_FILTER_TYPE_OPTIONS:
      return action.payload;
    default:
      return state;
  }
}
