import "../utils/StringUtils";
import React, { ReactElement } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import {
  faClock,
  faFlag,
  faInfo,
  faLocationArrow,
  faMapMarkedAlt,
  faMapPin,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import randomColor from "randomcolor";

export default class FCNode {
  static readonly FIELDS_TO_SHOW: Array<string> = [
    "name",
    "type",
    "status",
    "latitude",
    "country",
    "longitude",
    "zip",
    "siteContacts",
    "address",
    "timeZone",
  ];

  static readonly FIELDS_TO_DOWNLOAD: Array<string> = [
    "name",
    "type",
    "status",
    "latitude",
    "longitude",
    "address",
    "country",
    "zip",
    "timeZone",
  ];

  static readonly ATTRIBUTE_ICON_MAP: Record<string, IconDefinition> = {
    name: faAmazon,
    type: faInfo,
    longitude: faLocationArrow,
    latitude: faLocationArrow,
    timeZone: faClock,
    country: faFlag,
    address: faMapMarkedAlt,
    zip: faMapPin,
    siteContacts: faUsers,
  };

  name: string;
  type: string;
  typeColor: string;
  status: string;
  longitude: number;
  latitude: number;
  timeZone: string;
  country: string;
  zip: string;
  address: string;
  siteContacts: ReactElement;

  // Convert raw CSV data into FC nodes
  static fromCSV(rawCSVData: string): Array<FCNode> {
    const lines = rawCSVData.split("\n");
    const fcNodes: Array<FCNode> = [];
    // Ignore first line in the CSV
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split("\t");
      // Skipping blank lines
      if (currentLine.length > 1) {
        fcNodes.push(FCNode.convertRowToNode(currentLine));
      }
    }
    return fcNodes;
  }

  static toCSV(fcNodes: Array<FCNode>): Blob {
    const blobPart = fcNodes.map((fcNode: FCNode) => {
      return FCNode.FIELDS_TO_DOWNLOAD.map(
        (field: string) => `"${fcNode[field]}"`
      ).toString();
    });
    blobPart.unshift(FCNode.FIELDS_TO_DOWNLOAD.toString());
    return new Blob([blobPart.join("\n")], { type: "text/csv" });
  }

  // Current CSV format:
  // name	type	status	latitude	longitude	launchDate	address	zip	country	timeZone
  private static convertRowToNode(currentLine: Array<string>): FCNode {
    const fcNode = new FCNode();
    fcNode.name = currentLine[0];
    fcNode.type = currentLine[1];
    const typeHashCode = currentLine[1].toUpperCase().hashCode();
    fcNode.typeColor = randomColor({
      seed: typeHashCode,
      format: "rgbArray",
      luminosity: "bright",
    });
    fcNode.status = currentLine[2];
    fcNode.latitude = currentLine[3].toNumber(5);
    fcNode.longitude = currentLine[4].toNumber(5);
    fcNode.address = currentLine[6];
    fcNode.zip = currentLine[7];
    fcNode.country = currentLine[8];
    fcNode.timeZone = currentLine[9];
    const phoneToolLink = `https://phonetool.amazon.com/search?search_query%5Bfilter_type%5D=All+fields&search_query%5Bquery%5D=${fcNode.name}&sort_type=Manager&sort_order=DESC`;
    fcNode.siteContacts = (
      <a href={phoneToolLink} rel="noreferrer" target="_blank">
        Click for Phone Tool
      </a>
    );

    return fcNode;
  }

  static getIcon(attribute: string): IconDefinition {
    return attribute in FCNode.ATTRIBUTE_ICON_MAP
      ? FCNode.ATTRIBUTE_ICON_MAP[attribute]
      : faInfo;
  }
}
