import React, { Component } from "react";
import { connect } from "react-redux";
import JsClient from "../store/actions/JsClient";
import getData from "../store/actions/GetData";
import Nav from "../components/Nav";
import MidwayIdentityCredentialProvider from "../MidwayClient";
import "@amzn/awsui-components-react/index.css";
import Map from "../components/Map";
import Amplify, { Auth } from "aws-amplify";
import AnalyticsUtils from "../utils/AnalyticsUtils";
import Preset from "../models/Preset";

const MIDWAY_HOSTNAME = "midway-auth.amazon.com";

export interface StateProps {
  presets: Array<Preset>;
}

interface DispatchProps {
  getData: typeof getData;
}

interface State {
  currentUser: string | undefined;
  credentialsProvider: MidwayIdentityCredentialProvider | undefined;
}

type AppProps = StateProps & DispatchProps;

class App extends Component<AppProps, State> {
  state: State = {
    currentUser: undefined,
    credentialsProvider: undefined,
  };

  componentDidMount() {
    fetch("/settings.json", { credentials: "include" })
      .then((response) => response.json())
      .then((settings) => {
        const credentialsProvider = new MidwayIdentityCredentialProvider(
          settings
        );

        /*
         * Amplify's Authentication module is configured ahead of time using the
         * information from the settings.json, so that the
         * currentAuthenticatedUser can be checked before configuring Analytics.
         */
        Amplify.configure({
          Auth: {
            identityPoolId: credentialsProvider.params.IdentityPoolId,
            identityPoolRegion: credentialsProvider.region,
            region: credentialsProvider.region,
            refreshHandlers: {
              [`${MIDWAY_HOSTNAME}`]: credentialsProvider.refreshHandler,
            },
          },
        });

        credentialsProvider.withValidCredentials(this.validationCallback);

        this.setState({
          credentialsProvider: credentialsProvider,
        });
      })
      .catch((error) => {
        console.error(
          "Unable to load settings.json, forcing reload of page",
          error
        );
      });
    this.client = new JsClient({ onReady: this.clientLoaded });
  }

  clientLoaded = () => {
    this.props.getData(this.client);
  };

  /*
   * This function is a callback for when the MidwayClient has finished
   * validating and retrieving the credentialsProvider which can then be used to
   * get the federated subject, check user authentication, sign the user in, and
   * to authenticate/configure Amplify.
   */
  validationCallback = (
    credentialsProvider: MidwayIdentityCredentialProvider
  ) => {
    this.setState({
      currentUser: credentialsProvider.getFederatedSubject(),
    });
    /*
     * First, the currentAuthenticatedUser is retrieved:
     * If the currentAuthenticatedUser exists, then Analytics can be configured.
     * If the currentAuthenticatedUser fails due to either not existing, or not
     * being configured, then federatedSignIn must occur.
     */
    Auth.currentAuthenticatedUser()
      .then(() => {
        Amplify.configure({
          Analytics: {
            AWSPinpoint: {
              appId: credentialsProvider.params.PinpointAppId,
              region: credentialsProvider.region,
            },
          },
        });
        this.checkFavoritePreset();
      })
      .catch(() => {
        /*
         * Federated sign in is performed with the midway credentials and when
         * successful will configure Analytics.
         */
        Auth.federatedSignIn(
          MIDWAY_HOSTNAME,
          {
            token: credentialsProvider.params.Logins[`${MIDWAY_HOSTNAME}`],
            identity_id: credentialsProvider.params.IdentityId,
            expires_at: credentialsProvider.federated_details.exp,
          },
          { name: credentialsProvider.getFederatedSubject() }
        )
          .then(() => {
            Amplify.configure({
              Analytics: {
                AWSPinpoint: {
                  appId: credentialsProvider.params.PinpointAppId,
                  region: credentialsProvider.region,
                },
              },
            });
            this.checkFavoritePreset();
          })
          .catch((error: Error) => {
            console.log("FederatedSignIn Error:", error);
          });
      });
  };

  checkFavoritePreset() {
    const presets = this.props.presets;
    if (presets && presets.length) {
      const preset = presets.find((preset) => preset.favorite);
      if (preset) {
        AnalyticsUtils.recordLoadFavoritePresetEvent();
      }
    }
  }

  render() {
    const { credentialsProvider, currentUser } = this.state;
    return (
      <div>
        <Nav user={currentUser} />
        <Map credentialsProvider={credentialsProvider} />
      </div>
    );
  }
}

const mapStateToProps = (state: StateProps): StateProps => ({
  presets: state.presets,
});

const mapDispatchToProps: DispatchProps = {
  getData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
