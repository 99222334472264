import {
  LOAD_FILTER_TYPE_OPTIONS,
  LOAD_NODES,
  SET_CLUSTERED,
  SET_MAP_STYLE,
  SET_PRESETS,
  SET_QUERY,
  SET_SELECTED_STATUS,
  SET_SELECTED_TYPES,
  SET_SELECTED_NODES,
} from "./ActionTypes";
import FCNode from "../../models/FCNode";
import Preset from "../../models/Preset";
import SearchQuery from "../../models/SearchQuery";

export function loadFilterTypeOptions(
  filters: Array<{ id: string; label: string }>
): { type: string; payload: Array<{ id: string; label: string }> } {
  return {
    type: LOAD_FILTER_TYPE_OPTIONS,
    payload: filters,
  };
}

export function getNodesAsync(
  nodes: Array<FCNode>
): { type: string; payload: Array<FCNode> } {
  return {
    type: LOAD_NODES,
    payload: nodes,
  };
}

export function setClustered(
  clustered: boolean
): { type: string; payload: boolean } {
  return {
    type: SET_CLUSTERED,
    payload: clustered,
  };
}

export function setMapStyle(
  mapStyle: string
): { type: string; payload: string } {
  return {
    type: SET_MAP_STYLE,
    payload: mapStyle,
  };
}

export function setPresets(
  presets: Array<Preset>
): { type: string; payload: Array<Preset> } {
  return {
    type: SET_PRESETS,
    payload: presets,
  };
}

export function setQuery(
  queries: Array<string>
): { type: string; payload: Array<string> } {
  return {
    type: SET_QUERY,
    payload: [...queries],
  };
}

export function setSelectedStatus(
  filteredStatus: Array<string>
): { type: string; payload: Array<string> } {
  return {
    type: SET_SELECTED_STATUS,
    payload: filteredStatus,
  };
}

export function setSelectedTypes(
  filteredList: Array<string>
): { type: string; payload: Array<string> } {
  return {
    type: SET_SELECTED_TYPES,
    payload: filteredList,
  };
}

export function setSelectedNodes(
  filteredList: Array<string>
): { type: string; payload: Array<string> } {
  return {
    type: SET_SELECTED_NODES,
    payload: filteredList,
  };
}
