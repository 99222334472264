import { SET_CLUSTERED } from "../actions/ActionTypes";

export default function ClusteredReducer(
  state = true,
  action: Record<string, unknown>
): unknown {
  switch (action.type) {
    case SET_CLUSTERED:
      return action.payload;
    default:
      return state;
  }
}
