import React, { Component, ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusSquare,
  faPenSquare,
  faStar,
  faPlusSquare,
} from "@fortawesome/free-solid-svg-icons";
import Preset from "../models/Preset";
import { connect } from "react-redux";
import { setPresets } from "../store/actions/Actions";
import ViewState from "../models/ViewState";
import ModifyPresetModal from "./ModifyPresetModal";
import PresetModal from "./PresetModal";
import SearchQuery from "../models/SearchQuery";
import AnalyticsUtils from "../utils/AnalyticsUtils";

export const MAX_PRESETS = 6;

export interface StateProps {
  clustered: boolean;
  mapStyle: string;
  presets: Array<Preset>;
  query: SearchQuery;
  selectedStatus: Array<string>;
  selectedTypes: Array<string>;
}

export interface OwnProps {
  setPresets: typeof setPresets;
  viewState: ViewState;
  selectedPreset: Preset;
  onClickPreset: (preset: Preset) => void;
  onCreatePreset: (preset: Preset) => void;
}

interface State {
  interactedPreset: Preset;
  presets: Array<Preset>;
  editModalVisibility: boolean;
  favoriteModalVisibility: boolean;
  unfavoriteModalVisibility: boolean;
  createModalVisibility: boolean;
  deleteModalVisibility: boolean;
}

type MapPresetsProps = StateProps & OwnProps;

class MapPresets extends Component<MapPresetsProps, State> {
  constructor(props: Readonly<MapPresetsProps>) {
    super(props);
    this.state = {
      interactedPreset: null,
      presets: props.presets,
      editModalVisibility: false,
      favoriteModalVisibility: false,
      unfavoriteModalVisibility: false,
      createModalVisibility: false,
      deleteModalVisibility: false,
    };
  }

  onClickCreatePreset(): void {
    const {
      clustered,
      mapStyle,
      query,
      selectedTypes,
      selectedStatus,
      viewState,
    } = this.props;
    const preset = {
      title: "",
      favorite: false,
      viewState,
      clustered,
      mapStyle,
      query,
      selectedTypes,
      selectedStatus,
    };
    this.setState({
      interactedPreset: preset,
      createModalVisibility: true,
    });
  }

  onClickFavoritePreset(preset: Preset): void {
    this.setState({
      interactedPreset: preset,
      favoriteModalVisibility: true,
    });
  }

  onClickUnfavoritePreset(preset: Preset): void {
    this.setState({
      interactedPreset: preset,
      unfavoriteModalVisibility: true,
    });
  }
  onClickEditPreset(preset: Preset): void {
    this.setState({
      interactedPreset: preset,
      editModalVisibility: true,
    });
  }

  onClickDeletePreset(preset: Preset): void {
    this.setState({
      interactedPreset: preset,
      deleteModalVisibility: true,
    });
  }

  createPreset(title: string) {
    const {
      presets,
      clustered,
      mapStyle,
      query,
      selectedTypes,
      selectedStatus,
      viewState,
      setPresets,
      onCreatePreset,
    } = this.props;
    const preset = {
      title: title,
      favorite: false,
      viewState,
      clustered,
      mapStyle,
      query,
      selectedTypes,
      selectedStatus,
    };

    if (presets.length <= MAX_PRESETS) {
      presets.push(preset);
    }

    setPresets(presets);
    localStorage.setItem("presets", JSON.stringify(presets));
    this.setState({
      presets: presets,
      createModalVisibility: false,
    });
    onCreatePreset(preset);
    AnalyticsUtils.recordCreatePresetEvent(preset);
  }

  deletePreset(title: string): void {
    const { presets, setPresets } = this.props;

    const newPresets = presets.filter((preset) => {
      return preset.title !== title;
    });

    setPresets(newPresets);
    localStorage.setItem("presets", JSON.stringify(newPresets));

    this.setState({
      presets: newPresets,
      deleteModalVisibility: false,
    });
    AnalyticsUtils.recordDeletePresetEvent();
  }

  editPreset(title: string): void {
    const { presets, setPresets } = this.props;
    const { interactedPreset } = this.state;

    const newPresets = presets.map((preset) => {
      if (preset.title === interactedPreset.title) {
        preset.title = title;
      }
      return preset;
    });

    setPresets(newPresets);
    localStorage.setItem("presets", JSON.stringify(newPresets));

    this.setState({
      presets: newPresets,
      editModalVisibility: false,
    });
    AnalyticsUtils.recordEditPresetEvent();
  }

  favoritePreset(title: string): void {
    const { presets, setPresets } = this.props;

    const newPresets = presets.map((preset) => {
      preset.favorite = preset.title === title;
      return preset;
    });

    setPresets(newPresets);
    localStorage.setItem("presets", JSON.stringify(newPresets));

    this.setState({
      presets: newPresets,
      favoriteModalVisibility: false,
    });
    AnalyticsUtils.recordFavoritePresetEvent();
  }

  unfavoritePreset(): void {
    const { presets, setPresets } = this.props;

    const newPresets = presets.map((preset) => {
      preset.favorite = false;
      return preset;
    });

    setPresets(newPresets);
    localStorage.setItem("presets", JSON.stringify(newPresets));

    this.setState({
      presets: newPresets,
      unfavoriteModalVisibility: false,
    });
    AnalyticsUtils.recordUnfavoritePresetEvent();
  }

  render(): ReactElement {
    const {
      interactedPreset,
      presets,
      editModalVisibility,
      favoriteModalVisibility,
      unfavoriteModalVisibility,
      createModalVisibility,
      deleteModalVisibility,
    } = this.state;

    return (
      <div>
        {createModalVisibility && (
          <ModifyPresetModal
            preset={interactedPreset}
            presets={presets}
            header="Create Preset"
            onComplete={(title) => this.createPreset(title)}
            onDismiss={() => this.setState({ createModalVisibility: false })}
            visibility={createModalVisibility}
          />
        )}
        {editModalVisibility && (
          <ModifyPresetModal
            preset={interactedPreset}
            presets={presets}
            header="Edit Preset"
            onComplete={(title) => this.editPreset(title)}
            onDismiss={() => this.setState({ editModalVisibility: false })}
            visibility={editModalVisibility}
          />
        )}
        {deleteModalVisibility && (
          <PresetModal
            preset={interactedPreset}
            header="Delete Preset"
            prompt={`Are you sure you want to delete ${
              interactedPreset ? interactedPreset.title : "preset"
            }?`}
            onComplete={(title) => this.deletePreset(title)}
            onDismiss={() => this.setState({ deleteModalVisibility: false })}
            visibility={deleteModalVisibility}
          />
        )}
        {favoriteModalVisibility && (
          <PresetModal
            preset={interactedPreset}
            header="Favorite Preset"
            prompt={`Are you sure you want to favorite ${
              interactedPreset ? interactedPreset.title : "preset"
            }?`}
            onComplete={(title) => this.favoritePreset(title)}
            onDismiss={() => this.setState({ favoriteModalVisibility: false })}
            visibility={favoriteModalVisibility}
          />
        )}
        {unfavoriteModalVisibility && (
          <PresetModal
            preset={interactedPreset}
            header="Unfavorite Preset"
            prompt={`Are you sure you want to unfavorite ${
              interactedPreset ? interactedPreset.title : "preset"
            }?`}
            onComplete={() => this.unfavoritePreset()}
            onDismiss={() =>
              this.setState({ unfavoriteModalVisibility: false })
            }
            visibility={unfavoriteModalVisibility}
          />
        )}
        <div className="preset-controls-container">
          {presets.length < MAX_PRESETS && (
            <div className="preset-button-container">
              <a
                className="create-preset-button"
                title="Create Preset"
                href="#"
                onClick={() => this.onClickCreatePreset()}
              >
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  className="preset-button-icon"
                />
              </a>
            </div>
          )}
          {presets.map((preset) => (
            <div key={preset.title} className="preset-button-container">
              <div className="preset-button-options-container">
                <a
                  key={`${preset.title} Favorite Button`}
                  className="preset-button"
                  title={`${preset.favorite ? "Unfavorite" : "Favorite"} ${
                    preset.title
                  }`}
                  href="#"
                  onClick={
                    preset.favorite
                      ? () => this.onClickUnfavoritePreset(preset)
                      : () => this.onClickFavoritePreset(preset)
                  }
                >
                  <FontAwesomeIcon
                    icon={faStar}
                    className="preset-button-icon"
                  />
                  {preset.favorite ? "Unfavorite" : "Favorite"}
                </a>
                <a
                  key={`${preset.title} Edit Button`}
                  className="preset-button"
                  title={`Edit ${preset.title}`}
                  href="#"
                  onClick={() => this.onClickEditPreset(preset)}
                >
                  <FontAwesomeIcon
                    icon={faPenSquare}
                    className="preset-button-icon"
                  />
                  Edit
                </a>
                <a
                  key={`${preset.title} Delete Button`}
                  className="preset-button"
                  title={`Delete ${preset.title}`}
                  href="#"
                  onClick={() => this.onClickDeletePreset(preset)}
                >
                  <FontAwesomeIcon
                    icon={faMinusSquare}
                    className="preset-button-icon"
                  />
                  Delete
                </a>
              </div>
              <a
                key={`${preset.title} Preset Button`}
                className={`preset-button ${preset.favorite ? "favorite" : ""}`}
                title={`${preset.title} Preset`}
                href="#"
                onClick={() => this.props.onClickPreset(preset)}
              >
                {preset.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state): StateProps => ({
  presets: state.presets,
  query: state.query,
  selectedTypes: state.selectedTypes,
  selectedStatus: state.selectedStatus,
  clustered: state.clustered,
  mapStyle: state.mapStyle,
});

export default connect(mapStateToProps, {
  setPresets,
})(MapPresets);
