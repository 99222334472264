import { SET_SELECTED_NODES } from "../actions/ActionTypes";

export default function FCNodeReducer(
  state = [],
  action: Record<string, unknown>
): unknown {
  switch (action.type) {
    case SET_SELECTED_NODES:
      return action.payload;
    default:
      return state;
  }
}
