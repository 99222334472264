import React, { Component, ReactElement } from "react";
import { Popup } from "react-map-gl";
import FCNode from "../../models/FCNode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface Props {
  onClosePopupMarker: () => void;
  fcNodes: Array<FCNode>;
}

export default class PopupMarker extends Component<Props> {
  getPopupItem(node: FCNode): ReactElement {
    return (
      <div key={`popup-container-${node.name}`} className="popup-container">
        <h3 className="popup-title">{node.name}</h3>
        {FCNode.FIELDS_TO_SHOW.map((field: string) => {
          return (
            <div
              className="popup-item flex-row align-items-center"
              key={`popup-container-${node.name}-${field}`}
            >
              <FontAwesomeIcon
                icon={FCNode.getIcon(field)}
                className="popup-item-icon"
              />
              <div className="flex-column popup-item-container">
                <span className="popup-item-label">{field}</span>
                <span className="popup-item-value">{node[field]}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render(): ReactElement {
    const { fcNodes, onClosePopupMarker } = this.props;
    const { longitude, latitude } = fcNodes[0];
    return (
      <Popup
        tipSize={10}
        anchor="top"
        longitude={longitude}
        latitude={latitude}
        closeOnClick={false}
        offsetTop={20}
        captureScroll={true}
        onClose={() => onClosePopupMarker()}
      >
        {fcNodes.map((node: FCNode) => {
          return this.getPopupItem(node);
        })}
      </Popup>
    );
  }
}
