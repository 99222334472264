import React, { Component, ReactElement } from "react";
import { Button, Input, Modal } from "@amzn/awsui-components-react";
import Preset from "../models/Preset";

export interface OwnProps {
  header: string;
  onComplete: (title: string) => void;
  onDismiss: () => void;
  preset: Preset;
  presets: Array<Preset>;
  visibility: boolean;
}

interface State {
  title: string;
  invalid: boolean;
}

export default class ModifyPresetModal extends Component<OwnProps, State> {
  constructor(props: Readonly<OwnProps>) {
    super(props);
    this.state = {
      title: props.preset ? (props.preset.title ? props.preset.title : "") : "",
      invalid: false,
    };
  }

  onClickConfirm(): void {
    const { presets, onComplete } = this.props;
    const { title } = this.state;
    if (title.length) {
      if (
        presets.find(
          (preset) =>
            preset.title === title && preset.title !== this.props.preset.title
        )
      ) {
        this.setState({ invalid: true });
      } else {
        onComplete(title);
      }
    } else {
      this.setState({ invalid: true });
    }
  }

  getErrorText(): string {
    const { invalid, title } = this.state;
    let text = "";
    if (invalid && title === "") {
      text = "Preset must have title";
    } else if (invalid) {
      text = "Preset must have unique title";
    }
    return text;
  }

  render(): ReactElement {
    const { header, visibility, onDismiss } = this.props;
    const { invalid, title } = this.state;

    return (
      <Modal
        visible={visibility}
        header={header}
        size="max"
        onDismiss={() => onDismiss()}
        footer={
          <span className="awsui-util-f-r">
            <Button
              className="cancel-button"
              variant="link"
              onClick={() => onDismiss()}
            >
              Cancel
            </Button>
            <Button
              className="confirm-button"
              variant="primary"
              onClick={() => this.onClickConfirm()}
            >
              Confirm
            </Button>
          </span>
        }
      >
        <span>
          <h5>Preset Title</h5>
          {invalid && <div className="invalid-text">{this.getErrorText()}</div>}
          <Input
            className="title-input"
            value={title}
            placeholder="Enter preset title"
            onChange={(event) => this.setState({ title: event.detail.value })}
            invalid={invalid}
            onKeyup={(event) =>
              event.detail.key === "Enter" && this.onClickConfirm()
            }
          />
        </span>
      </Modal>
    );
  }
}
